import React from "react";
import styled from "styled-components";
import { DivColumn } from "../atoms/containers";

export const Page = styled(DivColumn)`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.background};
    overflow-y: auto;
`;
