import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { themes } from "./themes";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken, signIn } from "./redux/reducers/auth";
import { addSpace, fetchSpaces, fetchSpace, clearSpacesAction, deleteSpace } from "./redux/reducers/spaces";
import { addWidget, deleteWidget } from "./redux/reducers/widgets";
import Space from "./components/organisms/OldSpace";
import { Page } from "./components/molecules/pages";
import ReqTestPage from "./components/pages/ReqTestPage";
import AuthPage from "./components/pages/AuthPage";
import SpacePage from "./components/pages/SpacePage";


const widgets = [
  { x: 0, y: 0, w: 300, h: 150, color: 'red', text: 'Widget 1' },
  { x: 120, y: 90, w: 150, h: 150, color: 'green', text: 'Widget 2' },
];

export default function App() {
  const dispatch = useDispatch();
  const { token, loading, error } = useSelector(state => state.auth);

  useEffect(() => {
    if (!token && loading === 'idle') {
      // console.log("TOK REF")
      dispatch(refreshToken());
    }
  }, [token, loading, dispatch]);


  return (
    <ThemeProvider theme={themes.dark}>
      {/* <div>TMP</div> */}
      {/* <ReqTestPage/> */}
      {/* <Space w="100vw" h="100vh" spaceID="MrwwKkVcin6foRcKW4fg" /> */}
      {loading === 'pending' ? (
        <div>Reload the page if nothing happens</div>
      ) : error || !token ? (
        <AuthPage />
      ) : (
        <SpacePage/>
      )}
    </ThemeProvider>
  );
}
  

