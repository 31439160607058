import { useSelector } from "react-redux";

const themes = {
    dark: {
        colors: {
            alpha: {
                background: "rgba(0, 0, 255, 1)",
                text: "rgba(0, 0, 0, 1)",
            },
            widget: {
                // bar: "rgba(40, 40, 40, 1)",
                bar: "rgba(60, 60, 60, 1)",
                // background: "rgba(35, 35, 35, 0.95)",
                background: "rgba(65, 65, 65, 0.95)",
                cross: "rgba(237, 106, 94, 1)",
            },
            overlay: {
                border: "rgba(0, 0, 255, 1)",
            },
            button: "rgba(40, 40, 40, 1)",
            background: "rgba(15, 15, 15, 1)",
            dot: "rgba(72, 72, 72, 1)",
        }
    }
};

export { themes };

// export default function useTheme() {
//     return themes[useSelector(state => state.theme.theme)];
// }
