import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/API/api';
import { 
    addWidgetToSpaceAction, 
    updateWidgetInSpaceAction, 
    removeWidgetFromSpaceAction 
} from './spaces';


export const initialState = {
    data: {}, // full widget data by id
};

const widgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        addWidgetAction: (state, action) => {
            const { id } = action.payload;
            state.data[id] = action.payload;
            // console.log("Added Widget", state.data[id]);
        },
        addWidgetsAction: (state, action) => {
            // console.log("AWSA + ", action.payload);
            const widgets = action.payload;
            widgets.forEach(widget => {
                state.data[widget.id] = widget;
            });            
        },
        removeWidgetAction: (state, action) => {
            const id = action.payload;
            delete state.data[id];
        },
        updateWidgetAction: (state, action) => {
            const { id, position, size, data, content } = action.payload;
            if (position) state.data[id].position = position;
            if (size) state.data[id].size = size;
            if (data) state.data[id].data = data;
            if (content) state.data[id].content = content;
        },
        clearWidgetsAction: (state) => {
            state.data = {};
        },
    },
});

export const {
    addWidgetAction,
    addWidgetsAction,
    removeWidgetAction,
    updateWidgetAction,
    clearWidgetsAction
} = widgetsSlice.actions;


export const addWidget = ({
    position = { x: 105, y: 105 },
    size = { x: 105, y: 105 },
    data = { info: "test react" },
    content = "",
    spaceID,
    templateID = "DnEGbz7i8I1VgiYi9xnt"
}) => async (dispatch) => {
    try {
        const widgetParams = {
            position, size, data, content,
            space_id: spaceID,
            template_id: templateID
        };
        const response = await API.post('/widget', widgetParams);
        const newWidget = {
            id: response.data.id,
            ...widgetParams,
        };
        dispatch(addWidgetAction(newWidget));
        dispatch(addWidgetToSpaceAction({ spaceID, widget: newWidget }));
    } catch (error) {
        console.log(error);
    }
}

export const deleteWidget = (widgetID) => async (dispatch, getState) => {
    try {
        // console.log("D WS STATE", getState().widgets.data[widgetID].space_id);
        const spaceID = getState().widgets.data[widgetID].space_id;
        const response = await API.delete(`/widget/${widgetID}`);
        dispatch(removeWidgetAction(widgetID));
        // console.log("DEL WID", response.data);
        dispatch(removeWidgetFromSpaceAction({ spaceID, widgetID }));
    } catch (error) {
        console.log(error);
    }
}

export const fetchWidget = (widgetID) => async (dispatch) => {
    try {
        const response = await API.get(`/widget/${widgetID}`);
        dispatch(addWidgetAction(response.data));
        // dispatch(addSpaceAction(response.data));
    } catch (error) {
        console.log(error);
    }
}

export const updateWidget = (widgetID, changes) => async (dispatch, getState) => {
    try {
        // console.log("WID UPD ID", widgetID);
        // console.log("WID STATE", getState().widgets.data[widgetID]);
        // console.log("U WS STATE", getState().widgets.data[widgetID].space_id);
        const spaceID = getState().widgets.data[widgetID].space_id;
        const response = await API.put(`/widget/${widgetID}`, changes);
        dispatch(updateWidgetAction(response.data));
        dispatch(updateWidgetInSpaceAction({ spaceID, widgetID, changes }));
    } catch (error) {
        console.log(error);
    }
}

export default widgetsSlice.reducer;

