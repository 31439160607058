import styled from "styled-components";

export const DivContainer = styled.div`
    box-sizing: border-box;
    display: flex;

    align-items: ${props => props.align ?? "center"};
    align-self: ${props => props.alignSelf};
    justify-content: ${props => props.justify ?? "center"};
    
    width: ${props => props.w};
    height: ${props => props.h};
    min-height: ${props => props.minh};

    background-color: ${props => props.bgCol ? props.bgCol
        : props.A ? props.theme.colors.main.A
        : props.B ? props.theme.colors.main.B
        : props.C ? props.theme.colors.main.C
        : "transparent"
    };

    flex: ${props => props.flex};
    flex-grow: ${props => props.fgrow};
    flex-wrap: ${props => props.fwrap};
    
    padding-left: ${props => props.padx};
    padding-right: ${props => props.padx};
    padding-bottom: ${props => props.pady};
    padding-top: ${props => props.pady};
    padding: ${props => props.pad};

    overflow-x: ${props => props.ofx};
    overflow-y: ${props => props.ofy};

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const DivBox = styled(DivContainer)`
    min-height: ${props => props.h};
    max-height: ${props => props.h};
    min-width: ${props => props.w};
    max-width: ${props => props.w};
`;

export const DivRow = styled(DivContainer)`
    flex-direction: row;
    min-height: ${props => props.h};
    max-height: ${props => props.h};
`;

export const DivColumn = styled(DivContainer)`
    min-width: ${props => props.w};
    max-width: ${props => props.w};
    flex-direction: column;
`;
