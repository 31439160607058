import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from './firebase';

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        const uid = result.user.uid;
        return { token, uid };
    } catch (error) {
        console.error("Error during Google Sign-In", error);
        return null;
    }
};

export const tokenRefresh = async () => {
    const user = auth.currentUser;
    if (user) {
        return await user.getIdToken(true);
    }
    return null;
};

// export const listenForTokenChanges = (callback) => {
//     return onIdTokenChanged(auth, async (user) => {
//         if (user) {
//             const newToken = await user.getIdToken();
//             callback(newToken);
//         }
//     });
// };

