import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signInWithGoogle, tokenRefresh } from '../../services/firebase/auth';

// Async thunk for signing in
export const signIn = createAsyncThunk('auth/signIn', async () => {
  const { token, uid } = await signInWithGoogle();
  console.log("TOKEN", token);
  return { token, uid };
});

// Async thunk for refreshing the token
export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
  const newToken = await tokenRefresh();
  return { token: newToken };
});

// Initial state
export const initialState = {
  token: null,
  uid: null,
  loading: 'idle',
  error: null,
};

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Clear token and uid
    clearToken: (state) => {
      state.token = null;
      state.uid = null;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.token = action.payload.token;
        state.uid = action.payload.uid;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      // TODO: maybe handle pending, fulfilled
      // Handle token refresh
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.token;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { clearToken } = authSlice.actions;
export default authSlice.reducer;
