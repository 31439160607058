// api.js
import axios from 'axios';
import { refreshToken, signIn } from '../../redux/reducers/auth';
import { store } from '../../redux/store';  // Import your Redux store

// Create an Axios instance with the base URL
const instance = axios.create({
  baseURL: 'https://us-central1-dreams-labs.cloudfunctions.net/api/',
});

// Function to get the Firebase token from Redux
const getToken = () => {
  const state = store.getState();
  return state.auth.token;
};
const getLoading = () => {
  const state = store.getState();
  return state.auth.loading;
};

// Set up an interceptor to include the token in the headers of all requests
instance.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// TODO: fix this
// Axios response interceptor for handling 401 (403) errors
instance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    const status = error.response.status;
    if ([401, 403].includes(status) && !originalRequest._retry) {
      originalRequest._retry = true;
      // console.log("OR", originalRequest.headers.Authorization);
      if (status === 403) 
        await store.dispatch(refreshToken());
      if (status === 401 && getLoading() !== 'pending')
        await store.dispatch(signIn());
      // console.log("N", getToken());
      originalRequest.headers.Authorization = `Bearer ${getToken()}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default instance;
