// create a button component with styled-components
import styled from 'styled-components';

const SSButton = styled.button`
  background: ${props => props.theme.colors.button};
  border: none;
  color: ${props => props.textCol ?? "white"};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: ${props => props.fsz ?? "25px"};  
  font-weight: 600; 
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 15px;
`;


export default SSButton;

