import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWidget, fetchWidget, updateWidget } from '../../redux/reducers/widgets';

import interact from 'interactjs';
import { DivContainer, DivRow, DivBox } from '../atoms/containers';
import { Spacer } from '../atoms/spacers';
import { removeWidgetFromSpaceAction } from '../../redux/reducers/spaces';
import { AutoGrowTextArea } from '../atoms/texts';

const StyledRnd = styled(Rnd)`
// background-color: red;
`;

const RndWrap = ({children, pos, size, ...props}) => {
    return <StyledRnd 
        default={{
            x: pos.x, y: pos.y, 
            width: size.x, height: size.y
        }}
        dragGrid={[15, 15]}
        resizeGrid={[15, 15]}
        bounds="parent"
        dragHandleClassName="widget-drag-area"  // Only elements with this class name can initiate a drag
        {...props}
    >{children}</StyledRnd>;
};




const WidgetBarContainer = styled(DivRow)`
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    justify-content: start;
    background-color: ${props => props.theme.colors.widget.bar};
`;

const WidgetCrossButton = styled(DivBox).attrs({
    h: "15px", w: "15px",
})`
    background-color: ${props => props.theme.colors.widget.cross};
    border-radius: 10px;
`;

const WidgetDragBar = styled(DivContainer).attrs({
    className: 'widget-drag-area',
})`
    background-color: ${props => props.theme.colors.widget.bar};
`;

const WidgetBar = ({ deleteAction }) => {
    return (
        <WidgetBarContainer w="100%" h="30px">
            <Spacer w="7.5px"/>
            <WidgetCrossButton onClick={deleteAction}/>
            <WidgetDragBar w="100%" h="100%"/>
        </WidgetBarContainer>
    );
}




const WidgetContainer = styled.div`
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.widget.background};
    // background-color: blue;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    align-items: start;
    justify-content: start;
    overflow: hidden;
    border-radius: 15px;
    color: white;
`;


// const WidgetContent = styled.div`
//     width: 100%;
//     max-height: 100%;
//     max-width: 100%;
//     overflow-y: auto;
//     box-sizing: border-box;
//     padding: 0 15px;
//     line-height: 1.75;
//     background-color: purple;
//     word-break: break-all;
//     white-space: pre-wrap;
//     outline: none;
// `;

const WidgetContent = styled.textarea`
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    color: white;
    font-family: Arial;
    line-height: 1.25;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-wrap: break-word;
`;





const Widget = ({ widget, onDrag }) => {
    const dispatch = useDispatch();
    const [content, setContent] = useState(widget.content);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        setTimer(setTimeout(() => {
            if (content !== widget.content) {
                // console.log("CONTENT CHANGED");
                dispatch(updateWidget(widget.id, { content }));
            }
        }, 4000)); // 4 seconds
    }, [content]);


    const handleWidgetDelete = () => {
        if (widget.id) {
            // console.log("DELETING", widget.id);
            dispatch(deleteWidget(widget.id));
        }
    };


    // const handleMouseDown = (e) => {
    //     e.stopPropagation(); // Stop event from bubbling up to the Space component
    //     // ... any other logic for handling widget click
    // };


    const handleDrag = (e, data) => {
        // console.log("WHD", widget, widget.size.x, widget.size.y);
        onDrag(widget.id, data.deltaX, data.deltaY, data.x, data.y, widget.size.x, widget.size.y);
    };

    const hanleDragStop = (e, data) => {
        // console.log("DRAG STOP", data);
        dispatch(updateWidget(widget.id, { 
            position: {
                x: parseInt(data.x, 10),
                y: parseInt(data.y, 10)
            }
        }));
    };

    const handleResizeStop = (e, direction, ref, delta, position) => {
        // console.log("RESIZE STOP", { e, direction, ref, delta, position });
        // console.log("WH", parseInt(ref.style.width, 10), parseInt(ref.style.height, 10));
        dispatch(updateWidget(widget.id, {
            // TODO: !!! change to width, height after backend fix
            size: {
                x: parseInt(ref.clientWidth, 10), // "100px" -> 100
                y: parseInt(ref.clientHeight, 10)
            },
            position: {
                x: parseInt(position.x, 10),
                y: parseInt(position.y, 10)
            }
        }));
    };

    return (widget && 
        <RndWrap pos={widget.position} size={widget.size} 
            onDrag={handleDrag}
            onDragStop={hanleDragStop}
            onResizeStop={handleResizeStop}
            // onMouseDown={handleMouseDown}
        >
            <WidgetContainer className="widget">
                <WidgetBar w="100%" h="30px"
                    deleteAction={handleWidgetDelete}
                />
                <DivContainer className="widget-content" pad="10px" h="100%" w="100%">
                    <WidgetContent value={content}
                        onChange={(e) => {
                            // console.log("Widget content changed");
                            setContent(e.target.value);
                        }}
                    />
                </DivContainer>
            </WidgetContainer>
        </RndWrap>
    );
};

export default Widget;
