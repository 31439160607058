import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/reducers/auth";
import { addSpace, fetchSpaces, fetchSpace, clearSpacesAction, deleteSpace } from "../../redux/reducers/spaces";
import { addWidget, deleteWidget } from "../../redux/reducers/widgets";
import { Page } from "../molecules/pages";
import styled from "styled-components";

const H2 = styled.h2`
    max-width: 100%;
`;
const H5 = styled.h5`
    max-width: 100%;
    overflow: wrap;
    overflow-wrap: break-word;
`;

export default function ReqTestPage() {
    const dispatch = useDispatch();
    const { toke, uid } = useSelector((state) => ({
        toke: state.auth.token,
        uid: state.auth.uid,
    }));
    const spaces = useSelector(state => state.spaces.spaces);
    const widgets = useSelector(state => state.widgets.data);

    useEffect(() => {
        dispatch(fetchSpaces(uid));
        // .then(() => {
        //     console.log("APP", spaces, uid);
        // });
    }, [uid]);

    return (<Page justify="start" align="start" padx="50px">
        <H2>Auth</H2>
        <H5>TOKEN</H5>
        <H5>{toke}</H5>
        <H5>USER ID</H5>
        <H5>{uid}</H5>
        <button onClick={() => dispatch(signIn())}>Googel sign in</button>

        <H2>Spaces</H2>
        <button onClick={() => dispatch(clearSpacesAction())}>clear</button>
        <button onClick={() => dispatch(fetchSpaces(uid))}>Fetch spaces</button>
        <button onClick={() => dispatch(addSpace("Titled"))}>Add space</button>
        {Object.keys(spaces).length > 0 &&
            <button onClick={() => dispatch(fetchSpace(Object.keys(spaces)[0]))}>Fetch space</button>
        }
        {Object.keys(spaces).length > 0 &&
            <button onClick={() => dispatch(deleteSpace(Object.keys(spaces)[0]))}>Remove space</button>
        }
        <ul>{Object.keys(spaces).map((key) => (
            <li key={key}>
            {key + " - " + spaces[key]?.name}
            </li>
        ))}</ul>
        <H2>Widgets</H2>
        <button onClick={() => dispatch(addWidget({spaceID: "MrwwKkVcin6foRcKW4fg"}))}>Add widget</button>
        {Object.keys(widgets).length > 0 && 
            <button onClick={() => dispatch(deleteWidget(Object.keys(widgets)[0]))}>Delete widget</button>
        }   
        <ul>{Object.keys(widgets).map((key) => (
            <li key={key}>
            {key + " - " + widgets[key]?.content}
            </li>
        ))}</ul>
    </Page>);
};
