import React, { useEffect, useRef } from 'react';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpaces, addSpace, fetchSpace } from "../../redux/reducers/spaces";
import Space from '../organisms/OldSpace';
import { Page } from '../molecules/pages';
import { addAgent, fetchAgents, agentQuery } from '../../redux/reducers/agents';
import SSButton from '../atoms/buttons';
import { DivRow } from '../atoms/containers';
import { Spacer } from '../atoms/spacers';
import Overlay from '../organisms/OverlayPanel';

const H4 = styled.h4`
    // background: green;
    font-family: Arial Narrow;
`;

export const AlphaBar = styled(DivRow)`
    justify-content: start;
    background-color: ${props => props.theme.colors.alpha.background};
    color: ${props => props.theme.colors.alpha.text};
`;
export const AlphaLogo = styled("img")`
    height: 100%;
    width: auto;
`;

export const SpiralsGif = styled("img")`
    width: 100%;
    height: auto;
`;

export default function SpacePage() {
    const dispatch = useDispatch();
    const { uid } = useSelector(state => state.auth);
    
    const initialRender = useRef(true); // TODO: inspect better solution
    const { 
        loading: spacesLoading, 
        error: spacesError, 
        spaces 
    } = useSelector(state => state.spaces);
    const { 
        loading: agentsLoading, 
        error: agentsError, 
        agents 
    } = useSelector(state => state.agents);


    // Store the first agent and space for easier access

    // console.log("SP", agents, spaces);
    const agentID = Object.keys(agents).length > 0 ? Object.keys(agents)[0] : null;
    const spaceID = Object.keys(spaces).length > 0 ? Object.keys(spaces)[0] : null;
    // console.log("AID, SID", agentID, spaceID);

    // TODO: add then to handle creatiion of space/agent
    useEffect(() => {
        if (uid) {
            dispatch(fetchSpaces(uid)).then((res) => {
                // console.log("FETCHED SPSS", res.payload);
                if (Object.keys(res.payload).length == 0) {
                    // console.log("SP CR");
                    dispatch(addSpace("Alpha+ Playground"));
                }
            });
            dispatch(fetchAgents(uid)).then((res) => {
                // console.log("FETCHED AGSS", res.payload);
                if (Object.keys(res.payload).length == 0) {
                    console.log("AG CR");
                    dispatch(addAgent("Alpha+ Playground"));
                }
            });
        }
    }, [uid]);

    // useEffect(() => {
    //     if (!initialRender.current) {
    //         if (spacesLoading === "idle" && !spacesError) {
    //             if (Object.keys(spaces).length === 0) {
    //                 dispatch(addSpace("Temporary Playground"));
    //             }
    //         }
    //         if (agentsLoading === "idle" && !agentsError) {
    //             if (Object.keys(agents).length === 0) {
    //                 dispatch(addAgent("Temporary Agent"));
    //             }
    //         }
    //     }
    //     initialRender.current = false;
    // }, [spaces, agents]);



    const testQuery = () => {
        if (agentID && spaceID) {
            const q = "Pistol with the greatest damage in standoff according to my space?";
            dispatch(agentQuery({
                agentID: agentID,
                question: q,
                spaces: spaceID,
            }));
        }
    }

    return (<>
        {spaceID && agentID && <Overlay spaceID={spaceID} agentID={agentID}/>}
        <Page>
            {spaceID && agentID ? (
                <Space w="100%" h="calc(100% - 45px)" spaceID={spaceID}/>
            ) : (
                <SpiralsGif src="/blue-spiral.gif"/>
            )}
            {spaceID && agentID && <AlphaBar w="100%" h="45px">
                <AlphaLogo src="/logo.svg"/>
                <Spacer w="20px"/>
                <H4>SPIRALS ALPHA+</H4>
                <Spacer w="20px"/>
                <H4>☻</H4>
                <Spacer w="20px"/>
                <H4>CTRL+L TO ASK AGENT</H4>
                <Spacer w="20px"/>
                <H4>☻</H4>
                <Spacer w="20px"/>
                <H4>SELECT AREA TO ADD NOTE</H4>
            </AlphaBar>}
        </Page>
    </>);
}
