import React, { useRef } from 'react';
import styled from 'styled-components';

export const TextDiv = styled.div`
    color: ${props => props.textCol ?? "white"};
    font-size: 25px;
    // background-color: brown;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1.2; 
    min-height: 30px;  // Set a minimum height
    white-space: pre-wrap;  // Respect newlines and spaces
    word-wrap: break-word;  // Allow long words to be broken
    // box-sizing: border-box;  // Box sizing
`;

export const AutoGrowTextArea = ({ onChange, ...otherProps }) => {
  const divRef = useRef(null);

  const handleInput = (e) => {
    if (onChange) {
      onChange(e);
    }
    const div = divRef.current;
    div.style.height = 'auto';
    div.style.height = `${div.scrollHeight}px`;
    void div.offsetHeight;  // Force reflow
  };

  return (
    <TextDiv 
      ref={divRef}
      contentEditable
      suppressContentEditableWarning
      onInput={handleInput}
      {...otherProps}
    />
  );
};
