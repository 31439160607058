import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/API/api';


export const fetchAgents = createAsyncThunk('agents/fetchAgents',
  async (userID, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user/${userID}/agent`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// TODO: check
export const fetchAgent = createAsyncThunk('agents/fetchAgent',
  async (agentID, { rejectWithValue }) => {
    try {
      const response = await API.get(`/agent/${agentID}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAgent = createAsyncThunk('agents/addAgent',
  async (name, { rejectWithValue }) => {
    try {
      const response = await API.post('/agent', { name: name });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// TODO: check
export const updateAgent = createAsyncThunk('agents/updateAgent',
  async ({ agentID, updates }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/agent/${agentID}`, updates);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete an agent
export const deleteAgent = createAsyncThunk('agents/deleteAgent',
  async (agentID, { rejectWithValue }) => {
    try {
      await API.delete(`/agent/${agentID}`);
      return agentID;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Search agents
export const agentQuery = createAsyncThunk('agents/agentQuery',
    async ({ agentID, question, spaces, model = 'gpt-3.5-turbo' }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/agent/${agentID}/search`, {
        question: question,
        spaces: spaces,
        // model: 'gpt-4'
        model: 'gpt-3.5-turbo',
        suffix: '16k' // only for 3.5
      });
      // console.log("AGENT RESP", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const initialState = {
  agents: {},
  loading: 'idle',
  error: null,
  queryLoading: 'idle',
  queryError: null,
};

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    // Add your synchronous actions here if any
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.agents = action.payload;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addAgent.fulfilled, (state, action) => {
        // console.log("ADD AGENT FF", action.payload);
        state.agents[action.payload.id] = action.payload;
      })
      .addCase(agentQuery.pending, (state) => {
        state.queryLoading = 'pending';
      })
      .addCase(agentQuery.fulfilled, (state, action) => {
        state.queryLoading = 'idle';
        // Handle the query result here. This depends on your state shape and needs.
      })
      .addCase(agentQuery.rejected, (state, action) => {
        state.queryLoading = 'failed';
        state.queryError = action.error.message;
      });
      // Add similar case handlers for other async actions: 
      // fetchAgent, addAgent, updateAgent, deleteAgent, searchAgents
  },
});

export default agentsSlice.reducer;
