import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import Widget from '../molecules/OldWidget';
import { fetchSpace } from '../../redux/reducers/spaces';
import { addWidget } from '../../redux/reducers/widgets';


const SpaceContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.w};
  height: ${props => props.h};
  background-color: ${props => props.theme.colors.background};
`;

const ContentContainer = styled.div`
  position: absolute;
  width: 3000px;
  height: 3000px;
  // background-color: ${props => props.theme.colors.background};
  background-image: url("/sky-background.svg"); //radial-gradient(${props => props.theme.colors.dot} 0.5px, transparent 0.5px);
  background-size: 1344px 896px;
  will-change: transform;
  // background-position: 7.5px 7.5px;
`;

const Space = ({ w, h, spaceID }) => {
  const contentSize = 3000; // Content w & h in pixels
  const containerRef = useRef(null);
  const [transform, setTransform] = useState({ offsetX: 0, offsetY: 0 });
  const [tempWidget, setTempWidget] = useState(null);

  const dispatch = useDispatch();
  const widgets = useSelector(state => state.spaces.spacesData[spaceID]?.widgets);

  useEffect(() => {
    // console.log("FETCHING SPACE", spaceID);
    dispatch(fetchSpace(spaceID));
  }, []); 




  const handleMouseDown = (e) => {
    if (!e.target.closest('.widget')) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = Math.floor((e.clientX - rect.left - transform.offsetX) / 15) * 15;
      const y = Math.floor((e.clientY - rect.top - transform.offsetY) / 15) * 15;
      setTempWidget({ x, y, width: 0, height: 0 });
    }
  };
  const handleMouseMove = (e) => {
    if (tempWidget) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = Math.floor((e.clientX - rect.left - transform.offsetX) / 15) * 15;
      const y = Math.floor((e.clientY - rect.top - transform.offsetY) / 15) * 15;
      setTempWidget(prev => ({
        x: prev.x,
        y: prev.y,
        width: x - prev.x,
        height: y - prev.y
      }));
    }
  };
  const handleMouseUp = (e) => {
    if (tempWidget) {
      // Create a new widget here
      dispatch(addWidget({
        position: { x: tempWidget.x, y: tempWidget.y },
        size: { x: tempWidget.width, y: tempWidget.height },
        spaceID
      }))
    }
    setTempWidget(null);
  };



  const handleWheel = (event) => {
    const dx = event.deltaX;
    const dy = event.deltaY;
    if (event.target.closest('.widget-content')) {
      // console.log("WHEEL", dx, dy);
      if (Math.abs(dx) <= Math.abs(dy)) {
        return; // Allow default behavior for widget (scrolling)
      }
    }
    event.preventDefault();
    const container = containerRef.current;
    setTransform((prev) => ({
      offsetX: Math.min(Math.max(prev.offsetX - dx, container.clientWidth - contentSize), 0),
      offsetY: Math.min(Math.max(prev.offsetY - dy, container.clientHeight - contentSize), 0),
    }));
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('wheel', handleWheel, { passive: false });
    return () => container.removeEventListener('wheel', handleWheel);
  }, [contentSize]);


  const handleWidgetDrag = (widgetID, dx, dy, x, y, width, height) => {
      const buffer = 15; // Distance from the border to start scrolling
      const scrollSpeed = 15; // Speed of scrolling
    
      let newOffsetX = transform.offsetX;
      let newOffsetY = transform.offsetY;
    
      // Check the left border of the widget
      if (x + newOffsetX < buffer)
        newOffsetX += scrollSpeed;
      // Check the top border of the widget
      if (y + newOffsetY < buffer)
        newOffsetY += scrollSpeed;
      // Check the right border of the widget
      if (containerRef.current.clientWidth - (x + width + newOffsetX) < buffer)
        newOffsetX -= scrollSpeed;
      // Check the bottom border of the widget
      if (containerRef.current.clientHeight - (y + height + newOffsetY) < buffer)
        newOffsetY -= scrollSpeed;

      setTransform({
        offsetX: Math.min(Math.max(newOffsetX, containerRef.current.clientWidth - contentSize), 0),
        offsetY: Math.min(Math.max(newOffsetY, containerRef.current.clientHeight - contentSize), 0),
      });
  };

  return (
    <SpaceContainer w={w} h={h} ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <ContentContainer style={{
        top: transform.offsetY + 'px',
        left: transform.offsetX + 'px',
      }}>
        {tempWidget && (
          <div style={{
            position: 'absolute',
            left: `${tempWidget.x}px`,
            top: `${tempWidget.y}px`,
            width: `${tempWidget.width}px`,
            height: `${tempWidget.height}px`,
            backgroundColor: 'rgba(0, 0, 255, 0.5)'
          }}></div>
        )}
        {widgets && widgets.map((widget, index) => (
          <Widget key={"widget" + widget.id + "-" + index} widget={widget} onDrag={handleWidgetDrag} />
        ))}
      </ContentContainer>
    </SpaceContainer>
  );
};

export default Space;
