/* eslint-disable */
import styled from "styled-components";
import { DivContainer } from "./containers";

export const Spacer = styled.div`
    height: ${props => (props.h ?? "10px")};
    min-height: ${props => (props.h ?? "10px")};
    width: ${props => (props.w ?? "10px")};
    min-width: ${props => (props.w ?? "10px")};
    flex-grow: ${props => props.grow};
    background-color: ${props => props.col ?? "transparent"}
`;

export const Divider = styled(DivContainer)`
    min-height: 35%;
    max-height: 35%;
    min-width: 4px;
    max-width: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: ${props => props.col ?? (
        props.A ? props.theme.colors.main.A :
        props.B ? props.theme.colors.main.B :
        props.theme.colors.main.C
    )};
`;

export const SpacerFluid = styled(Spacer)`
    display: flex;
    flex: 1;
`;
