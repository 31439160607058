import React, { useEffect } from "react";
import { Page } from "../molecules/pages";
import styled from "styled-components";
import SSButton from "../atoms/buttons";
import { useDispatch } from "react-redux";
import { signIn } from "../../redux/reducers/auth";
import { DivBox } from "../atoms/containers";

const H1 = styled.h1`
    max-width: 100%;
    font-weight: 500;
    color: white;
`;

const CloudBackground = styled(DivBox)`
    width: 100%;
    height: 100%;
    background-image: url("/sky-background.svg");
    background-size: cover;
`;

const AuthButton = styled("img").attrs({
    src: "/auth-button.svg"
})`
    width: 200px;
    height: auto;
`;

export default function AuthPage() {
    const dispatch = useDispatch();
    
    // useEffect(() => {
    //     dispatch(signIn());
    // }, []);

    return (
        <Page justify="center" align="center">
            <CloudBackground>
                <DivBox onClick={() => dispatch(signIn())}>
                    <AuthButton/>
                </DivBox>
            </CloudBackground>
        </Page>
    );
}
