import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addWidgetsAction } from './widgets';
import API from '../../services/API/api';




export const addSpace = (
    name = "Untitled", 
    isPublic = false
) => async (dispatch) => {
    try {
        const response = await API.post('/space', {
            public: isPublic,
            name: name
        });
        // console.log("AS", response.data);
        dispatch(addSpaceAction({
            id: response.data.id,
            name: name
        }));
    } catch (error) {
      console.error('Error adding space:', error);
    }
};

export const deleteSpace = (spaceID) => async (dispatch) => {
    try {
        // console.log("RS ID", spaceID);
        const resp = await API.delete(`/space/${spaceID}`);
        // console.log("RS", resp);
        dispatch(removeSpaceAction(spaceID));
    } catch (error) {
        console.error('Error removing space:', error);
    }
};





export const fetchSpaces = createAsyncThunk('spaces/fetchSpaces',
    async (userID, { rejectWithValue }) => {
        try {
            const response = await API.get(`/user/${userID}/space`);
            // console.log("SPACES", Object.entries(response.data));
            const filteredData = Object.fromEntries(
                Object.entries(response.data).filter(
                    ([_, value]) => value != null
                )
            );
            return filteredData;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
  
// export const fetchSpace = createAsyncThunk('spaces/fetchSpace',
//     async (spaceID, { rejectWithValue }) => {
//         try {
//             const response = await API.get(`/space/${spaceID}`);
//             // return { ...response.data, id: spaceID };
//         } catch (error) {
//             return rejectWithValue(error);
//         }
//     }
// );
export const fetchSpace = createAsyncThunk('spaces/fetchSpace',
    async (spaceID, thunkAPI) => {
        try {
            const response = await API.get(`/space/${spaceID}`);
            const spaceData = { ...response.data, id: spaceID };

            // Let's say response.data.widgets is an array of widgets related to the space
            if (spaceData.widgets && Array.isArray(spaceData.widgets)) {
                thunkAPI.dispatch(addWidgetsAction(spaceData.widgets));
            }

            return spaceData;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);



// TODO: change to list, data
export const initialState = {
  spaces: {}, // only { id, name } by id
  spacesData: {}, // full spaces data by id
  loading: "idle",
  error: null,
};

const spacesSlice = createSlice({
    name: 'spaces',
    initialState,
    reducers: {
        addSpaceAction: (state, action) => {
            const { id, name } = action.payload;
            state.spaces[id] = action.payload;
        },
        updateSpacesAction: (state, action) => {
            state.spaces = action.payload;
        },
        removeSpaceAction: (state, action) => {
            const id = action.payload;
            delete state.spaces[id];
            delete state.spacesData[id];
        },
        // TODO: check if all values are handled properly
        updateSpaceAction: (state, action) => {
            const { id, name, widgets, portals } = action.payload;
            // console.log("USA", id, name, widgets, portals);
            // console.log("SPACES", Object.keys(state.spaces));

            if (!(id in state.spaces))
                state.spaces[id] = { id: id };
            if (name) state.spaces[id].name = name;

            if (!(id in state.spacesData))
                state.spacesData[id] = { id: id };
            if (widgets) state.spacesData[id].widgets = widgets;
            if (portals) state.spacesData[id].portals = portals;
        },
        addWidgetToSpaceAction: (state, action) => {
            const { spaceID, widget } = action.payload;
            if (spaceID in state.spacesData) {
                const widgetIndex = state.spacesData[spaceID].widgets.findIndex(w => w.id === widget.id);
                if (widgetIndex !== -1) {
                    state.spacesData[spaceID].widgets[widgetIndex] = widget;
                } else {
                    state.spacesData[spaceID].widgets.push(widget);
                }
            } else {
                // console.log("KEK AWTSA");
            }
        },
        removeWidgetFromSpaceAction: (state, action) => {
            const { spaceID, widgetID } = action.payload;
            if (spaceID in state.spacesData) {
                if (state.spacesData[spaceID].widgets.some(w => w.id === widgetID)) {
                    state.spacesData[spaceID].widgets = state.spacesData[spaceID].widgets.filter(w => w.id !== widgetID);
                }
            } else {
                // console.log("KEK RWFSA");
            }
        },
        updateWidgetInSpaceAction: (state, action) => {
            const { spaceID, widgetID, changes } = action.payload;
            if (spaceID in state.spacesData) {
                const widgetIndex = state.spacesData[spaceID].widgets.findIndex(w => w.id === widgetID);
                if (widgetIndex !== -1) {
                    state.spacesData[spaceID].widgets[widgetIndex] = {
                        ...state.spacesData[spaceID].widgets[widgetIndex],
                        ...changes
                    };
                }
            } else {
                // console.log("KEK UWISA");
            }
        },
        clearSpacesAction: (state) => {
            state.spaces = {};
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSpaces.pending, (state) => {
            state.loading = 'pending';
            state.spacesData = {};
        })
        .addCase(fetchSpaces.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.spaces = action.payload;
        })
        .addCase(fetchSpaces.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchSpace.pending, (state) => {
            // state.loading = 'pending';
        })
        .addCase(fetchSpace.fulfilled, (state, action) => {
            // state.loading = 'idle';
            state.spacesData[action.payload.id] = action.payload;
        })
        .addCase(fetchSpace.rejected, (state, action) => {
            // state.loading = 'failed';
            state.error = action.error.message;
        });
    },
});

export const { 
    updateSpacesAction, 
    addSpaceAction, 
    removeSpaceAction, 
    updateSpaceAction, 
    clearSpacesAction,
    addWidgetToSpaceAction,
    removeWidgetFromSpaceAction,
    updateWidgetInSpaceAction
} = spacesSlice.actions;



export default spacesSlice.reducer;



// export const fetchSpaces = (userID) => async (dispatch) => {
//     try {
//         const response = await API.get(`/user/${userID}/space`);
//         console.log("FS", response.data);
//         const filteredData = Object.fromEntries(
//             Object.entries(response.data).filter(
//                 ([_, value]) => value != null
//             )
//         );
//         console.log("FSF", filteredData);
//         dispatch(updateSpacesAction(filteredData));
//     } catch (error) {
//         console.error('Error fetching spaces:', error);
//     }
// }

// export const fetchSpace = (spaceID) => async (dispatch) => {
//     try {
//         const response = await API.get(`/space/${spaceID}`);
//         response.data.id = spaceID;
//         console.log("FS1", response.data);
//         dispatch(updateSpaceAction(response.data));
//         dispatch(addWidgetsAction(response.data.widgets));
//     } catch (error) {
//         console.error('Error fetching space:', error);
//     }
// }



